import React from "react";
import MenuItem from "./MenuItem";
import MenuItemLink from "./MenuItemLink";

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.sendData = this.sendData.bind(this);
  }

  sendData(props) {
    this.props.parentCallback("false");
  }
  render() {
    return (
      <div
        style={this.props.isActive ? { height: "100%" } : { height: "0%" }}
        className="SideBarMenu"
        onClick={this.sendData}>
        <div className="menuItemsOuter">
          <MenuItem
            isActive={this.props.isActive ? " item-is-active" : ""}
            itemName="Über Uns"
            linkTo={"About"}
            onClickItem={this.sendData}
          />
          <MenuItemLink
            isActive={this.props.isActive ? " item-is-active" : ""}
            itemName="Unser Team"
            linkTo={"/team"}
            onClickItem={this.sendData}
          />
          <MenuItem
            isActive={this.props.isActive ? " item-is-active" : ""}
            itemName="Spenden"
            linkTo={"Donation"}
            onClickItem={this.sendData}
          />
          <MenuItem
            isActive={this.props.isActive ? " item-is-active" : ""}
            itemName="Kontakt"
            linkTo={"Contact"}
            onClickItem={this.sendData}
          />
        </div>
      </div>
    );
  }
}

export default SideBarMenu;
