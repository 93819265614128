import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./footerStyles.css";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import mail from "../images/mail.svg";
import { GAevent } from "../Cookies";

class FooterSocial extends React.Component {
  render() {
    return (
      <Container
        className="footerSocialContainer"
        style={{ width: "100%" }}
        fluid="true">
        <Row className="footerSocialRow">
          <Col lg={6} md={6} sm={6} xs={12} className="footerSocialText">
            Folge uns auf unseren Social Media Kanälen!
          </Col>
          <Col
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className="footerSocialPicsContainer">
            <a
              onClick={() => GAevent("Social", "Facebook wurde geöffnet.")}
              className="footerSocialPicsLink"
              href="https://www.facebook.com/StupoliHH/">
              <img className="footerSocialPics" src={facebook} />
            </a>
            <a
              onClick={() => GAevent("Social", "Instagram wurde geöffnet.")}
              className="footerSocialPicsLink"
              href="https://www.instagram.com/stupoli_hamburg/?hl=de">
              <img className="footerSocialPics" src={instagram} />
            </a>
            <a
              onClick={() => GAevent("Social", "Mail wurde geklickt.")}
              className="footerSocialPicsLink"
              href="mailto:moin@stupoli-hamburg.de">
              <img className="footerSocialPics" src={mail} />
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FooterSocial;
