import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./donatorsStyles.css";

class DonatorsTemplate extends React.Component {
  render() {
    return (
      <a>
        <Container fluid="true" className="donatorsTemplateContainer">
          <Row>
            <Col xs={4} sm={12} md={12} lg={12}>
              <span className={this.props.classNameCustom}>
                <img
                  alt="Spender-Logo"
                  className="donatorsBannerImage"
                  src={this.props.imageSource}></img>
              </span>
            </Col>
            <Col className="sponsorText" xs={8} sm={12} md={12} lg={12}>
              {this.props.sponsorText}
            </Col>
          </Row>
        </Container>
      </a>
    );
  }
}

export default DonatorsTemplate;
