import React from "react";
import "./donationStyles.css";
import paypal from "../images/paypal.svg";
import { GAevent } from "../Cookies";
class DonationMoney extends React.Component {
  render() {
    return (
      <div className="donationBannerOuter">
        <div className="donationBannerInner">
          <img
            alt="BannerImage"
            className="donationBannerImage"
            src={this.props.imageSource}></img>
          <h1 className="donationBannerHeader">Spenden</h1>
          <div className="donationBannerLine"></div>

          <h2
            style={{ paddingTop: "15px" }}
            className="donationBannerHeaderInfo">
            Empfänger
          </h2>
          <p className="donationBannerTextInfo">StuPoli Hamburg</p>
          <h2 className="donationBannerHeaderInfo">IBAN</h2>
          <p className="donationBannerTextInfo">DE24 2005 0550 1026 2254 56</p>

          <h2 className="donationBannerHeaderInfo">Institut</h2>
          <p className="donationBannerTextInfo">Hamburger Sparkasse</p>
          <h2 className="donationBannerHeaderInfo">Spendenbescheinigung</h2>

          <p className="donationBannerTextInfo">
            Ab einer Spende von 50€ ausstellbar
          </p>
        </div>

        <form
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top">
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="K7AXVZLFUW8KN" />

          <button
            name="submit"
            className="donationButton"
            onClick={() =>
              GAevent("PayPal-Button", "PayPal-Button wurde gedrückt.")
            }>
            <img className="donationButtonImage" src={paypal}></img>Mit PayPal
            spenden
          </button>
        </form>
      </div>
    );
  }
}

export default DonationMoney;
// <p className="donationBannerText">
//   Die StuPoli Hamburg finanziert sich ausschließlich über Spenden.
//   Über Ihre Hilfe freuen wir uns!
// </p>
