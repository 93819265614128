import React from "react";
import DonationMoney from "./DonationMoney";
import DonationThings from "./DonationThings_New";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import donation from "../images/donation.svg";
import drugs from "../images/drugs.svg";

class DonationAssembled extends React.Component {
  render() {
    return (
      <div className="donationOuter">
        <h1 className="donationHeadline">Spenden</h1>
        <Container style={{ width: "100%" }} fluid="true">
          <Row>
            <Col sm={12} md={12} lg={6}>
              <DonationMoney imageSource={donation} />
            </Col>
            <Col sm={12} md={12} lg={6}>
              <DonationThings imageSource={drugs} />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DonationAssembled;
