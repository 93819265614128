import React from "react";
import "./donationStyles.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { GAevent } from "../Cookies";
import ReCAPTCHA from "react-google-recaptcha";

class DonationMoney extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consentGiven: false,
      sendSuccess: false,
      name: "",
      email: "",
      message: "",
      human: false,
      humanKey: null,
      disabled: false,
    };
    this.onClickConsense = this.onClickConsense.bind(this);
  }

  onClickConsense(e) {
    e.preventDefault();

    if (this.state.consentGiven === false) {
      this.setState({ consentGiven: true });
      console.log("clicked");
    } else {
      this.setState({ consentGiven: false });
      this.setState({ human: false, humanKey: null });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.human) {
      axios({
        method: "POST",
        url: process.env.REACT_APP_MAIL_URL,
        data: this.state,
      }).then((response) => {
        if (response.data.status === "success") {
          this.setState({ sendSuccess: "onclick" });

          setTimeout(
            function () {
              this.setState({ sendSuccess: "send" });
            }.bind(this),
            4000
          );
          setTimeout(
            function () {
              this.setState({ sendSuccess: false });
              this.resetForm();
            }.bind(this),
            6000
          );
          GAevent(
            "Kontaktformular",
            "Kontakt wurde über Kontaktformular aufgenommen."
          );
        } else if (response.data.status === "fail") {
          alert("Es ist ein Fehler aufgetreten.");
          console.log(response);
        }
      });
    } else {
      alert(
        "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      );
    }
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      message: "",
      human: false,
      consentGiven: false,
      humanKey: null,
    });
  }

  // ReCAPTCHA-Zeugs
  onCaptchaLoad = () => {
    console.log("Captcha loaded.");
  };
  verifyCaptcha = (res) => {
    if (res) {
      this.setState(
        { human: true, humanKey: res },
        console.log("Human-Key: " + this.state.humanKey)
      );
      this.setState({ disabled: this.isDisabled() });
    }
  };

  // ReCAPTCHA Expired
  expireCaptcha = () => {
    this.setState({ human: false, humanKey: null });
    this.setState({ disabled: this.isDisabled() });
  };

  render() {
    return (
      <div className="donationThingsBannerOuter">
        <div className="donationThingsBannerInner">
          <img
            className="donationThingsBannerImage"
            src={this.props.imageSource}></img>
          <h1 className="donationThingsBannerHeader">Kontaktformular</h1>
          <div className="donationThingsBannerLine"></div>
          <form onSubmit={this.handleSubmit.bind(this)} method="POST">
            <input
              type="text"
              name="subject"
              required
              value="Sachspende"
              hidden
              className="donationThingsInput"></input>
            <input
              name="Name"
              id="name"
              placeholder="Ihr Name"
              type="text"
              required
              className="donationThingsInput"
              value={this.state.name}
              onChange={this.onNameChange.bind(this)}></input>
            <input
              name="User-Mail"
              placeholder="Ihre Mail-Adresse"
              type="email"
              id="email"
              required
              className="donationThingsInput"
              value={this.state.email}
              onChange={this.onEmailChange.bind(this)}></input>
            <input
              name="text"
              placeholder="Ihre Nachricht"
              type="text"
              id="message"
              required
              className="donationThingsInputText"
              value={this.state.message}
              onChange={this.onMessageChange.bind(this)}></input>
            <div className="donationConsense">
              <input
                type="button"
                required
                className={
                  this.state.consentGiven
                    ? "buttonConsense is-active"
                    : "buttonConsense"
                }
                onClick={this.onClickConsense}></input>
              <label>
                Ich bin mit der{" "}
                <Link className="consentLink" to="/page/datenschutz">
                  Datenschutzerklärung
                </Link>{" "}
                einverstanden
              </label>
            </div>
            <ReCAPTCHA
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
              className={
                this.state.consentGiven ? "showCaptcha" : "hideCaptcha"
              }
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              // render="explicit" // onloadCallback={this.onCaptchaLoad}
              onChange={this.verifyCaptcha}
              onExpired={this.expireCaptcha}
            />
            <button
              type="submit"
              disabled={!this.state.consentGiven}
              className={
                this.state.sendSuccess == "send"
                  ? "donationThingsButton validate"
                  : this.state.sendSuccess == "onclick"
                  ? "donationThingsButton onclick"
                  : this.state.human && this.state.consentGiven
                  ? "donationThingsButton "
                  : "donationThingsButton disabledButton"
              }>
              {this.state.sendSuccess ? "Gesendet" : "Absenden"}
            </button>
          </form>
        </div>
      </div>
    );
  }
  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }
}

export default DonationMoney;
