import React from "react";
import stupoli_logo from "./../../images/stupoli_logo.svg";
import "./controlsStyle.css";
import { Link } from "react-router-dom";

class LogoHeader extends React.Component {
  render() {
    return (
      <Link to={"/"} className="logoHeaderOuter">
        <img alt="Logo" className="logoHeader" src={stupoli_logo}></img>
      </Link>
    );
  }
}

export default LogoHeader;
