import React from "react";
import { Link } from "react-router-dom";

class GoBack extends React.Component {
  render() {
    return (
      <Link to="/" className="arrowMenuContainer">
        <button>
          <span className="arrow-box">
            <span className="arrow-inner first"></span>
            <span className="arrow-inner second"></span>
            <span className="arrow-inner third"></span>
          </span>
        </button>
      </Link>
    );
  }
}

export default GoBack;
