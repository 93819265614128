import React from "react";
import { Link } from "react-router-dom";

class MenuItem extends React.Component {
  render() {
    return (
      <div>
        <Link
          className={"MenuItem " + this.props.isActive}
          to={this.props.linkTo}>
          <p className="hoverTrick">{this.props.itemName}</p>
        </Link>
      </div>
    );
  }
}

export default MenuItem;
