import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MiniHeader from "../MiniHeader/MiniHeaderAssembled";
import { GApageView } from "../Cookies";
// import { MatomoPageView } from "../Cookies";

import "./pageStyles.css";
import logo from "../images/logo_dark.svg";

import parse from "html-react-parser";

const API = process.env.REACT_APP_GHOST_URL + "/api/v3/content/pages";
const KEY = "/?key=" + process.env.REACT_APP_GHOST_API_KEY;

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pages: [],
      pageTitle: "",
      error: null,
      empty: false,
    };
  }
  fetchPosts() {
    fetch(API + KEY)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          pages: data.pages,
          isLoading: false,
        })
      )
      .catch((error) => this.setState({ error, isLoading: false }));
  }
  setNoPage() {
    this.setState({ empty: true });
  }
  componentDidMount() {
    this.fetchPosts();
    GApageView("Datenschutz, Impressum, Haftungsausschluss");
    // MatomoPageView("Datenschutz, Impressum, Haftungsausschluss")
  }
  render() {
    console.log(this.state.pages);
    const { isLoading, pages, error } = this.state;
    const empty_page_check = pages.map((key) => key.slug);

    let pages_array;
    if (isLoading) {
      pages_array = (
        <div>
          <MiniHeader />
          <Container fluid="true" className="postPageContainer">
            <div className="loadingWrapper">
              <img src={logo} className="loadingAnimation" />
              <div className="loadingAnimationDotWrapper">
                <span className="loadingAnimationDot one" />
                <span className="loadingAnimationDot two" />
                <span className="loadingAnimationDot three" />
              </div>
            </div>
          </Container>
        </div>
      );
    } else if (empty_page_check.includes(this.props.match.params.pageId)) {
      pages_array = pages.map((key) => {
        if (this.props.match.params.pageId == key.slug) {
          return (
            <div>
              <MiniHeader pageTitle={key.title} />
              <Container fluid="true" className="postPageContainer">
                <Row className="postPageRow" id={key.id}>
                  <div className="post-content">{parse(key.html)}</div>
                </Row>
              </Container>
            </div>
          );
        }
      });
    } else {
      pages_array = (
        <div>
          <MiniHeader />
          <Container fluid="true" className="postPageContainer">
            <div className="notFoundWrapper">
              <div className="notFoundTextWrapper">
                <h1>404</h1>
                <h5>Ups. Diese Seite konnten wir leider nicht finden.</h5>
              </div>
            </div>
          </Container>
        </div>
      );
    }

    return <div>{pages_array}</div>;
  }
}

export default Page;
