import React from "react";
import PostCard from "./PostCard";
import "./postStyles.css";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const API =
  process.env.REACT_APP_GHOST_URL +
  "/api/v3/content/posts/?key=" +
  process.env.REACT_APP_GHOST_API_KEY;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      posts: [],
      error: null,
      status: null,
    };
  }
  fetchPostsNew() {
    axios
      .get(API)
      .then((response) =>
        this.setState({
          posts: response.data.posts,
          status: response.status,
          isLoading: false,
        })
      )
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: true,
        });
      });
  }

  fetchPosts() {
    fetch(API)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          posts: data.posts,
          isLoading: false,
        })
      )
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.fetchPostsNew();
  }

  render() {
    const { isLoading, posts, error } = this.state;
    const posts_array = posts.map((key) => (
      <Col md={12} lg={6} sm={12}>
        <PostCard
          image={key.feature_image}
          id={key.id}
          uuid={key.id}
          title={key.title}
          link={key.url}
        />
      </Col>
    ));
    console.log("Posts:" + posts_array);
    return (
      <Container fluid="true" className="postAssembledContainer">
        {posts_array != "" && <h2>Neuigkeiten</h2>}

        <Row>{posts_array}</Row>
      </Container>
    );
  }
}

export default App;
