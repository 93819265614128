import React from "react";
import homeless_bw from "../images/homeless_bw.png";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
class MiniHero extends React.Component {
  render() {
    return (
      <div>
        <Container
          style={{
            backgroundColor: "black"
          }}
          fluid="true"
          className="heroContainer"
        >
          <div className="miniHeroPictureOuter">
            <Row className="miniHeroPictureRow">
              <Col lg={6} md={12} sm={12}>
                <img
                  alt="Schwarzweiß-Bild eines Obdachlosen"
                  style={{ zIndex: 0 }}
                  className="miniHeroPicture"
                  src={homeless_bw}
                ></img>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
                md={12}
                lg={6}
                sm={12}
              >
                <div className="miniHeroTextOuter">{this.props.pageTitle}</div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default MiniHero;
