import React from "react";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
const cookies = new Cookies();
class CookieSettings extends React.Component {
  handleClick() {
    cookies.remove("_ga", { path: "/" });
    cookies.remove("_gat", { path: "/" });
    cookies.remove("_gid", { path: "/" });
    cookies.remove("rcl_statistics_consent");
    cookies.remove("rcl_preferences_consent");
    cookies.remove("rcl_consent_given");
    setTimeout(
      function () {
        window.location.reload();
      }.bind(this),
      500
    );
  }
  render() {
    return <Link onClick={() => this.handleClick()}>Cookieeinstellungen</Link>;
  }
}

export default CookieSettings;
