import React from "react";
import "./miniHeaderStyles.css";
import Logo from "./Logo";
import Container from "react-bootstrap/Container";
import GoBack from "./GoBack";
import MiniHero from "./MiniHero";
class MiniHeaderAssembled extends React.Component {
  render() {
    return (
      <Container
        style={{
          backgroundColor: "black"
        }}
        fluid="true"
        className="MiniHeaderContainer"
      >
        <div className="heroOuter">
          <div className="heroInner">
            <GoBack />
            <Logo />
          </div>
          <MiniHero pageTitle={this.props.pageTitle} />
        </div>
      </Container>
    );
  }
}
export default MiniHeaderAssembled;
