import React from "react";
import "./hamburgers.css";
import SideBarMenu from "./SideBarMenu";

class BurgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuActive: false,
      isActive: null
    };
    this.onClickHandler = this.onClickHandler.bind(this);
  }
  onClickHandler() {
    console.log("clicked");
    if (this.state.isMenuActive === false) {
      this.setState({ isMenuActive: true });
      this.setState({ isActive: "hamburger hamburger--elastic is-active" });
    } else {
      this.setState({ isMenuActive: false });
      this.setState({ isActive: "hamburger hamburger--elastic" });
    }
    console.log(this.state.isMenuActive);
    console.log(this.state.isActive);
  }

  callbackFunction = childData => {
    this.setState({ isMenuActive: false });
    this.setState({ isActive: "hamburger hamburger--elastic" });
  };

  render() {
    return (
      <div className="burgerMenuContainer">
        <SideBarMenu
          parentCallback={this.callbackFunction}
          isActive={this.state.isMenuActive}
        />

        <button
          onClick={this.onClickHandler}
          className={"hamburger" + "hamburger--elastic" + this.state.isActive}
          type="button"
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>
      </div>
    );
  }
}

export default BurgerMenu;
