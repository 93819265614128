import React from "react";
import AboutBanner from "./AboutBanner";
import heart from "../images/heart.svg";
import life_belt from "../images/life_belt.svg";
import location from "../images/location.svg";
import donation from "../images/donation_red.svg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class AboutAssembled extends React.Component {
  render() {
    return (
      <div className="aboutAssembledContainer">
        <h2>Über Uns</h2>
        <Container className="aboutContainer" fluid="true">
          <Row>
            <Col className="aboutBannerCol" sm={12} md={12} lg={6}>
              <AboutBanner
                imageSource={heart}
                aboutBannerHeader="Hilfe für alle. Kostenlos."
                aboutBannerText="Seit Februar 2018 erweitert die StuPoli Hamburg das Netz aus kostenlosen Sprechstundenangeboten für versicherungslose und hilfsbedürftige Menschen. Unser Projekt ist von Studenten realisiert und organisiert."
              />
            </Col>
            <Col className="aboutBannerCol" sm={12} md={12} lg={6}>
              <AboutBanner
                imageSource={life_belt}
                aboutBannerHeader="Unsere Leistungen"
                aboutBannerText="Die StuPoli Hamburg bietet allen versicherungslosen Menschen eine kostenlose Sprechstunde an. Falls ein Patient Medikamente, Verbandsmaterialien oder anderen medizinischen Bedarf benötigt, ist er bei uns gut aufgehoben!"
              />
            </Col>
          </Row>
          <Row>
            <Col className="aboutBannerCol" sm={12} md={12} lg={6}>
              <AboutBanner
                imageSource={location}
                aboutBannerHeader="Räumlichkeit"
                aboutBannerText="Unsere Sprechstunde findet jeden Freitag im CaFée mit Herz (CmH) im Gesundheitszentrum St. Pauli von 14:00 - 16:00 Uhr  statt. Vor der Sprechstunde können unsere Patienten auch das CmH besuchen."
              />
            </Col>
            <Col className="aboutBannerCol" sm={12} md={12} lg={6}>
              <AboutBanner
                imageSource={donation}
                aboutBannerHeader="Spenden"
                aboutBannerText="Wir finanzieren uns ausschließlich über Spenden, daher freuen wir uns über jede Unterstützung! Da wir ein eingetragener Verein (e.V.) sind, können wir auf Wunsch  eine Spendenbescheinigung ausstellen."
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AboutAssembled;
