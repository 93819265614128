import React from "react";
import {
  Link,
  Events,
  animateScroll as scroll,
} from "react-scroll";

class MenuItem extends React.Component {
  componentDidMount() {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments);
    });
  }

  render() {
    return (
      <div>
        <Link
          className={"MenuItem " + this.props.isActive}
          to={this.props.linkTo}
          spy={true}
          smooth={"easeInOutQuad"}
          duration={1000}
          offset={-100}
          delay={300}
          onClick={this.props.onClickItem}>
          <p className="hoverTrick">{this.props.itemName}</p>
        </Link>
      </div>
    );
  }
}

export default MenuItem;
