import React from "react";

function GoogleHelper() {
  return (
    <p style={{ display: "none" }}>
      Die StuPoli Hamburg ist eine Anlaufstelle für kostenlose und
      unbürokratische medizinische Versorgung in Hamburg.
    </p>
  );
}

export default GoogleHelper;
