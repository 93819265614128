import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeaderAssembled from "../hero/controls/HeaderAssembled";
import { Helmet } from "react-helmet";
import MiniHeader from "../MiniHeader/MiniHeaderAssembled";
import logo from "../images/logo_dark.svg";
import axios from "axios";
import { GApageView } from "../Cookies";
// import { MatomoPageView } from "../Cookies";

import parse from "html-react-parser";

const API = process.env.REACT_APP_GHOST_URL + "/api/v3/content/posts/";
const KEY = "/?key=" + process.env.REACT_APP_GHOST_API_KEY;

class BlogPost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      posts: [],
      error: null,
      status: null,
      empty_page_check: "",
    };
  }
  fetchPostsNew() {
    axios
      .get(API + this.props.match.params.postId + KEY)
      .then((response) =>
        this.setState({
          posts: response.data.posts,
          status: response.status,
          isLoading: false,
        })
      )
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: true,
        });
      });
  }

  fetchPosts() {
    fetch(API + this.props.match.params.postId + KEY)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          posts: data.posts,
          isLoading: false,
        })
      )
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  checkEmpty() {
    if (this.state.posts == null) {
      const empty_page_check = "empty";
      this.setState({ empty_page_check: empty_page_check });
    } else {
      const empty_page_check = this.state.posts.map((key) => key);
      this.setState({ empty_page_check: empty_page_check });
    }
  }

  componentDidMount() {
    this.fetchPostsNew();
    this.checkEmpty();
    GApageView("Blog");
    // MatomoPageView("Blog");
  }

  render() {
    const { isLoading, posts, error, empty_page_check } = this.state;

    let posts_array;
    if (isLoading) {
      posts_array = (
        <div>
          <MiniHeader />
          <Helmet>
            <title>Lädt... - StuPoli Hamburg e.V.</title>
          </Helmet>
          <Container fluid="true" className="postPageContainer">
            <div className="loadingWrapper">
              <img
                src={logo}
                alt="Logo der StuPoli"
                className="loadingAnimation"
              />
              <div className="loadingAnimationDotWrapper">
                <span className="loadingAnimationDot one" />
                <span className="loadingAnimationDot two" />
                <span className="loadingAnimationDot three" />
              </div>
            </div>
          </Container>
        </div>
      );
    } else if (error == true) {
      posts_array = (
        <div>
          <Helmet>
            <title>404 - StuPoli Hamburg e.V.</title>
          </Helmet>
          <MiniHeader pageTitle="Seite nicht gefunden." />
          <Container fluid="true" className="postPageContainer">
            <div className="notFoundWrapper">
              <div className="notFoundTextWrapper">
                <h1>404</h1>
                <h5>Ups. Diese Seite konnten wir leider nicht finden.</h5>
              </div>
            </div>
          </Container>
        </div>
      );
    } else if (empty_page_check != "empty") {
      posts_array = posts.map((key) => {
        const reading_time = (key.reading_time / 5) * 100;
        return (
          <div>
            <MiniHeader pageTitle={key.title} />
            <Helmet>
              <title>Blog - StuPoli Hamburg e.V.</title>
            </Helmet>
            <Row className="postPageRow" id={key.id}>
              <Col
                className="postPageCol"
                lg={12}
                md={12}
                sm={12}
                xl={12}
                xs={12}>
                <div className="postPageDiv">
                  <div className="postPageImgContainer">
                    <img src={key.feature_image} />
                  </div>
                  <div className="postPageInfoContainer">
                    <h2>{key.title}</h2>
                    <span className="postLine"></span>
                    <p>{key.excerpt}</p>
                    <div className="readingTimeContainer">
                      <div
                        className="readingTimeInner"
                        style={{ width: reading_time }}></div>
                    </div>
                    <div className="readingTimeText">
                      {key.reading_time >= 1 ? key.reading_time : "1"} min
                      Lesezeit
                    </div>
                  </div>
                </div>
              </Col>
              <div className="post-content">{parse(key.html)}</div>
            </Row>
          </div>
        );
      });
    }

    return (
      <Container fluid="true" className="postPageContainer transition-item">
        {posts_array}
      </Container>
    );
  }
}

export default BlogPost;

// API + this.props.match.params.postId + KEY
