import React from "react";
import DonatorsTemplate from "./DonatorsTemplate";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./donatorsStyles.css";

import rotary from "../images/Rotary.svg";
import asklepios from "../images/Asklepios.svg";
import cmh from "../images/cmh.jpeg";
import huk from "../images/huk.svg";

class DonationAssembled extends React.Component {
  render() {
    return (
      <div>
        <div className="zigzagTop"></div>
        <Container
          className="donatorsContainer"
          style={{ width: "100%" }}
          fluid="true">
          <h2>Unsere Partner und Sponsoren</h2>
          <Row>
            <Col xs={12} sm={6} md={3} lg={3}>
              <DonatorsTemplate
                imageSource={rotary}
                homepageLink={"https://hamburg-lombardsbruecke.rotary.de"}
                classNameCustom="rotaryClass"
                sponsorText={"Rotary Club Hamburg-Lombardsbrücke"}
              />
            </Col>
            <Col xs={12} sm={6} md={3} lg={3}>
              <DonatorsTemplate
                imageSource={asklepios}
                homepageLink={"https://www.asklepios.com/ach/"}
                classNameCustom="asklepiosClass"
                sponsorText={"Asklepios Campus Hamburg"}
              />
            </Col>
            <Col xs={12} sm={6} md={3} lg={3}>
              <DonatorsTemplate
                imageSource={cmh}
                homepageLink={"https://cafeemitherz.de"}
                classNameCustom="cmhClass"
                sponsorText={"Cafée mit Herz"}
              />
            </Col>
            <Col xs={12} sm={6} md={3} lg={3}>
              <DonatorsTemplate
                imageSource={huk}
                homepageLink={"https://www.huk.de"}
                classNameCustom="tafelClass"
                sponsorText={"HUK Coburg"}
              />
            </Col>
          </Row>
        </Container>
        <div className="zigzagBottom"></div>
      </div>
    );
  }
}

export default DonationAssembled;
