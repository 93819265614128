import React from "react";
import BurgerMenu from "./BurgerMenu";
import LogoHeader from "./LogoHeader";
import VisibilitySensor from "react-visibility-sensor";

class HeaderAssembled extends React.Component {
  render() {
    return (
      <VisibilitySensor scrollCheck="true" offset={{ top: -20 }}>
        {({ isVisible }) => (
          <div
            style={
              isVisible
                ? { backgroundColor: "black" }
                : { backgroundColor: "transparent" }
            }
            className="heroOuter"
          >
            <div className="heroInner">
              <BurgerMenu />
              <LogoHeader />
            </div>
          </div>
        )}
      </VisibilitySensor>
    );
  }
}

export default HeaderAssembled;
