import React from "react";
import "./cookieStyles.css";
import cookie_img from "../images/cookie.svg";
import ReactGA from "react-ga";
import { CookieBanner } from "@palmabit/react-cookie-law";

// import MatomoTracker from "@datapunt/matomo-tracker-js";

// const tracker = new MatomoTracker({
//   urlBase: "https://matomo.stupoli-hamburg.de/",
//   siteId: 1, // optional, default value: `1`

//   linkTracking: true, // optional, default value: true
// });

class CookieConsent extends React.Component {
  render() {
    const trackingId = "UA-156587207-1";
    return (
      <CookieBanner
        data-nosnippet
        message="Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis
        zu bieten. Dazu zählen Cookies, die für den Betrieb der Seite
        notwendig sind, sowie solche, die lediglich zu anonymen
        Statistikzwecken genutzt werden. Sie können selbst entscheiden,
        welche Kategorien Sie zulassen möchten. Bitte beachten Sie, dass
        auf Basis Ihrer Einstellungen womöglich nicht mehr alle
        Funktionalitäten der Seite zur Verfügung stehen. Weitere
        Informationen finden Sie in unseren Datenschutzhinweisen."
        wholeDomain={true}
        cookie_img={cookie_img}
        headline="Cookie-Einstellungen"
        showPreferencesOption={false}
        red
        wholeDomain={true}
        showMarketingOption={false}
        necessaryOptionText="Notwendig"
        statisticsOptionText="Statistik"
        onAccept={() => {}}
        onAcceptStatistics={() => {
          ReactGA.initialize(trackingId);
        }}
        acceptButtonText="Akzeptieren"
        policyLink="/page/datenschutz"
        privacyPolicyLinkText="Datenschutzhinweise"
        styles={{}}
        showDeclineButton={true}
        declineButtonText="Ablehnen"
      />
    );
  }
}
export default CookieConsent;

export const GApageView = (page) => {
  ReactGA.pageview(page);
};

export const GAevent = (categoryName, eventName) => {
  ReactGA.event({
    category: categoryName, // Required
    action: eventName, // Required
    label: "labelName",
    value: 10,
    nonInteraction: false,
  });
};

// export const MatomoEventTracker = (categoryName, eventName) => {
//   tracker.trackEvent({
//     category: categoryName,
//     action: eventName,
//     name: eventName, // optional
//   });
// };
// export const MatomoPageView = (siteName) => {
//   tracker.trackPageView({
//     documentTitle: siteName, // optional
//   });
// };
