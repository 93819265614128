import React from "react";

import "./mapStyles.css";
import pin from "../images/pin.png";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ReactMapboxGl, { Layer, Feature, Marker } from "react-mapbox-gl";

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        width: 100,
        height: 300,
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 8,
      },
      bool: false,
    };
    this.ctrlFunction = this.ctrlFunction.bind(this);
    this.ctrlOffFunction = this.ctrlOffFunction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.ctrlFunction, false);
    document.addEventListener("keyup", this.ctrlOffFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.ctrlFunction, false);
    document.addEventListener("keyup", this.ctrlOffFunction, false);
  }

  ctrlFunction(event) {
    if (event.keyCode === 18) {
      this.setState({ bool: true });
    }
  }
  ctrlOffFunction(event) {
    if (event.keyCode === 18) {
      this.setState({ bool: false });
    }
  }
  resizeMap = (width) => {
    console.log(width);
    this.setState({
      width: width,
    });
  };

  render() {
    const Map = ReactMapboxGl({
      scrollZoom: this.state.bool,
      accessToken:
        "pk.eyJ1Ijoic2ViYXN0aWFuYXB3IiwiYSI6ImNqdzI0OW0zdjA1MTA0YW82bGVjaHB2MG0ifQ.IsNriFyUFZQ4Dxk-aFLnlg",
    });
    return (
      <Container className="mapContainer" fluid="true">
        <Row className="mapOuter">
          <Col className="mapInner">
            <Map
              style="mapbox://styles/mapbox/streets-v11"
              containerStyle={{
                height: "50vh",
                width: "100%",
              }}
              className="mapStyle"
              zoom={[13]}
              center={[9.9694833, 53.5478167]}>
              <Marker
                coordinates={[9.9694833, 53.5478167]}
                style={{ height: "10px" }}
                className="markerStyle"
                anchor="bottom">
                <img src={pin} />
              </Marker>
              <Layer type="symbol" id="marker">
                <Feature coordinates={[9.9694833, 53.5478167]} />
              </Layer>
            </Map>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Map;
