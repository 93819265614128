import React from "react";

import "./footerStyles.css";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import stupoli_logo from "../images/stupoli_logo.svg";
import line from "../images/line.svg";
import mail from "../images/mail.svg";
import pin_white from "../images/pin_white.svg";
import CookieSettings from "../Cookies/CookieSettings";
class Footer extends React.Component {
  render() {
    return (
      <Container fluid="true">
        <Row className="footerInfoRow">
          <Col className="footerLogoOuter" lg={4} md={4} sm={12} xs={12}>
            <img className="logoFooter" alt="StuPoli Logo" src={stupoli_logo} />
            <img className="lineFooter" alt="StuPoli Logo" src={line} />
            <h2>Hilfe für Alle. Kostenlos.</h2>
            <p>
              Die StuPoli Hamburg ist ein gemeinnütziges studentisches Projekt
              in Zusammenarbeit mit dem Asklepios Campus Hamburg und dem CaFée
              mit Herz in St. Pauli. Wir bieten unbürokratische medizinische
              Beratung und Behandlung ohne unangenehme Fragen nach dem
              Versicherungsschutz.
            </p>
          </Col>
          <Col className="contactFooter" lg={4} md={4} sm={4} xs={12}>
            <img
              className="lineFooter lineHide"
              alt="StuPoli Logo"
              src={line}
            />
            <h2>Kontakt</h2>
            <table>
              <tr>
                <a
                  className="footerSocialPicsLink"
                  href="mailto:moin@stupoli-hamburg.de">
                  <td>
                    <img className="footerContactPics" src={mail} />
                  </td>
                  <td>moin@stupoli-hamburg.de</td>
                </a>
              </tr>
              <tr>
                <a
                  className="footerSocialPicsLink"
                  href="https://goo.gl/maps/2pM8kLJqPvRTC91d8">
                  <td>
                    <img className="footerContactPics" src={pin_white} />
                  </td>
                  <td>
                    Seewartenstraße 10 <br />
                    20459 Hamburg
                  </td>
                </a>
              </tr>
            </table>
          </Col>
          <Col className="linksFooter" lg={4} md={4} sm={4} xs={12}>
            <img
              className="lineFooter lineHide"
              alt="StuPoli Logo"
              src={line}
            />

            <h2>Links</h2>
            <div>
              <Link to={"/page/" + "datenschutz"}>Datenschutz</Link>
            </div>
            <div>
              <Link to={"/page/" + "haftungsausschluss"}>
                Haftungsausschluss
              </Link>
            </div>
            <div>
              <Link to={"/page/" + "impressum"}>Impressum</Link>
            </div>
            <div>
              <a href="https://wiki.stupoli-hamburg.de/flyer_druckerei.pdf">
                Flyer herunterladen
              </a>
            </div>
            <div>
              <CookieSettings />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Footer;
