import React from "react";
import Hero from "../hero/hero";
import About from "../AboutSection/AboutAssembled";
import Donators from "../Donators/Donators";
import HomeElement from "./HomeElement";
import Donation from "../Donation/DonationAssembled";
import Map from "../Contact/Map";
import Blog from "../Blog/PostAll";
import { Helmet } from "react-helmet";

class HomeAll extends React.Component {
  componentDidMount() {
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>StuPoli Hamburg e.V.</title>
          <meta
            name="description"
            content="Die StuPoli Hamburg ist eine Anlaufstelle für kostenlose und unbürokratische medizinische Versorgung in Hamburg."
          />
        </Helmet>
        <HomeElement
          sectionItemAnchor="Hero"
          sectionId="Hero"
          sectionItem={<Hero />}
        />
        <HomeElement
          sectionItemAnchor="About"
          sectionId="About"
          sectionItem={<About />}
        />
        <HomeElement sectionItemAnchor="Donators" sectionItem={<Donators />} />
        <HomeElement sectionItemAnchor="Donation" sectionItem={<Donation />} />
        <HomeElement sectionItemAnchor="Blog" sectionItem={<Blog />} />
        <HomeElement sectionItemAnchor="Contact" sectionItem={<Map />} />
      </div>
    );
  }
}

export default HomeAll;

// <HomeElement sectionItemAnchor="Footer" sectionItem={<Footer />} />
