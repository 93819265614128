import React from "react";
import ReactTypingEffect from "react-typing-effect";
import "./controlsStyle.css";
class Type extends React.Component {
  render() {
    return (
      <p>
        <ReactTypingEffect
          staticText={this.props.staticText}
          speed={60}
          eraseDelay={this.props.eraseDelay}
          className="redText"
          style={{ lineBreak: "auto" }}
          text={this.props.typeSequence}
          cursor={this.props.cursor}
          cursorClassName="cursorText"
          stopped
        />
      </p>
    );
  }
}

export default Type;
