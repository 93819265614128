import React from "react";
import * as Scroll from "react-scroll";
let Element = Scroll.Element;

class HomeAll extends React.Component {
  render() {
    return (
      <div>
        <Element id={this.props.sectionId} name={this.props.sectionItemAnchor}>
          {this.props.sectionItem}
        </Element>
      </div>
    );
  }
}

export default HomeAll;
// <Map />
