import React from "react";
import "./aboutStyles.css";

class AboutBanner extends React.Component {
  render() {
    return (
      <div className="aboutBannerOuter">
        <div className="aboutBannerInner">
          <img
            alt="About"
            className="aboutBannerImage"
            src={this.props.imageSource}></img>
          <h1 className="aboutBannerHeader">{this.props.aboutBannerHeader}</h1>
          <div className="aboutBannerLine"></div>
          <p className="aboutBannerText">{this.props.aboutBannerText}</p>
        </div>
      </div>
    );
  }
}

export default AboutBanner;
