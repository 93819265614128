import React from "react";

import "./footerStyles.css";
import FooterSocial from "./FooterSocial";
import FooterInfo from "./FooterInfo";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import linered from "../images/line_red.svg";

class Footer extends React.Component {
  render() {
    return (
      <div className="footerOuter">
        <FooterSocial />
        <FooterInfo />
        <img className="copyrightFooterLine" src={linered} />
        <div className="copyrightFooterP">© 2020 StuPoli Hamburg</div>
      </div>
    );
  }
}

export default Footer;
