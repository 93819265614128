import React from "react";
import HeaderAssembled from "./controls/HeaderAssembled";

import Type from "./controls/TypeIt";
import "./heroStyles.css";

import homeless_bw from "./../images/homeless_bw.png";
import pin from "./../images/pin_white.svg";
import clock from "./../images/clock.svg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Hero extends React.Component {
  render() {
    return (
      <div>
        <Container
          style={{
            backgroundColor: "black",
          }}
          fluid="true"
          className="heroContainer">
          <div className="heroOuter">
            <div className="heroInner">
              <HeaderAssembled />
            </div>
          </div>
          <div className="heroPictureOuter">
            <Row className="heroPictureRow" className="heroVisible">
              <Col lg={6} md={12} sm={12}>
                <img
                  style={{ zIndex: 0 }}
                  className="heroPicture"
                  src={homeless_bw}></img>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                md={12}
                lg={6}
                sm={12}>
                <div className="heroTextOuter">
                  <Type
                    eraseDelay={2000}
                    cursor="|"
                    staticText={
                      <b className="whiteText" style={{ color: "#ffffff" }}>
                        Jeder Mensch verdient
                      </b>
                    }
                    typeSequence={["Hilfe.", "Zuwendung.", "Würde."]}
                  />
                </div>
              </Col>
            </Row>
          </div>
          )}
        </Container>
        <div className="heroOverhangSpacer"></div>
        <div className="heroOverhang">
          <Container fluid="true">
            <Row>
              <Col lg={6} md={6} sm={6} xs={12}>
                <div className="heroOverhangContainer">
                  <Row>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      xs={3}
                      className="heroOverhangPicContainer">
                      <img className="heroOverhangPic" src={pin} />
                    </Col>
                    <Col
                      lg={9}
                      md={9}
                      sm={9}
                      xs={9}
                      className="heroOverhangText">
                      <h3>Standort</h3>
                      <p>
                        Seewartenstraße 10 <br /> 20459 Hamburg
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6} lg={6} sm={6} xs={12}>
                <div className="heroOverhangContainer">
                  <Row>
                    <Col
                      lg={3}
                      md={3}
                      sm={3}
                      xs={3}
                      className="heroOverhangPicContainer">
                      <img className="heroOverhangPic" src={clock} />
                    </Col>
                    <Col
                      lg={9}
                      md={9}
                      sm={9}
                      xs={9}
                      className="heroOverhangText">
                      <h3>Öffnungszeiten</h3>
                      <p>Freitags 14:00 - 16:00 Uhr</p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Hero;
