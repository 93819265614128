import React from "react";
import { Link } from "react-router-dom";
import "./postStyles.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

class PostCard extends React.Component {
  render() {
    return (
      <Link class="postBannerLink" to={"/post/" + this.props.uuid}>
        <div
          className="postBannerOuter"
          style={{ backgroundImage: "url(" + this.props.image + ")" }}
        >
          <div class="postCardContent">
            <h2 class="post-card-title">{this.props.title}</h2>
          </div>
        </div>
      </Link>
    );
  }
}

export default PostCard;
