import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import HomeAll from "./AssembledAll/HomeAll";
import About from "./AboutSection/AboutAssembled";
import PostPage from "./Blog/PostPage";
import Page from "./Pages/Page";
import Footer from "./Footer/Footer";
import Team from "./Team/TeamPage";
import ScrollToTop from "react-router-scroll-top";
import posed, { PoseGroup } from "react-pose";
import Cookies from "./Cookies";
import GoogleHelper from "./Cookies/GoogleHelper";
import "animate.css/animate.min.css";
import { createBrowserHistory } from "history";
import { Helmet } from "react-helmet";
import logo_png from "./images/stupoli_logo.png";
const browserHistory = createBrowserHistory();
class App extends React.Component {
  render() {
    return (
      <BrowserRouter history={browserHistory}>
        <Helmet>
          <title>StuPoli Hamburg e.V.</title>
          <meta
            name="description"
            content="Die StuPoli Hamburg ist eine Anlaufstelle für kostenlose und unbürokratische medizinische Versorgung in Hamburg."
          />
        </Helmet>
        <ScrollToTop>
          <img
            style={{ display: "none" }}
            src={logo_png}
            alt="StuPoli-Logo grau im PNG-Format"
          />
          <Cookies />
          <Switch>
            <Route exact path="/" component={HomeAll} />
            <Route exact path="/post/:postId" component={PostPage} />
            <Route exact path="/page/:pageId" component={Page} />
            <Route exact path="/team" component={Team} />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </ScrollToTop>{" "}
        <Footer />
      </BrowserRouter>
    );
  }
}
export default App;
